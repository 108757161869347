import { Link } from "gatsby"
import styled from "styled-components"

import { colors, media, transition } from "@variables"
import { AccessibleButton } from "@components/shared/styled"

export const LogoLink = styled(Link)`
    height: 40px; // img height

    img {
        max-width: 160px;
    }
`

export const Wrap = styled.header`
    width: 100%;
    background-color: white;
`

export const Inner = styled.nav`
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Ul = styled.ul`
    list-style: none;
    display: none;

    ${media.phone} {
        display: flex;
    }
`

export const Li = styled.li`
    color: ${colors.red};

    :not(:last-of-type) {
        padding-right: 25px;
    }
`

export const Burger = styled(AccessibleButton)<{ readonly isOpen: boolean }>`
    width: 40px;
    height: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    will-change: opacity;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    transition: ${transition.main};
    overflow: hidden;
    position: relative;

    ${media.phone} {
        display: none;
    }

    span {
        width: 20px;
        height: 1px;
        position: relative;
        will-change: transform opacity;
        transition: ${transition.main};
        background-color: ${colors.black};

        ${props =>
            props.isOpen &&
            `
            transform-origin: 47%;

            :nth-of-type(1) {
                transform: rotate(45deg);
            }
            :nth-of-type(2) {
                transform: rotate(-45deg);
            }
            :last-of-type {
                opacity: 0;
            }
        `}

        ${props =>
            !props.isOpen && `:not(:last-of-type) { margin-bottom: 3px; }`}
    }
`
