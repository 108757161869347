import styled from "styled-components"

import { colors, media } from "@variables"

export const Wrap = styled.div`
    border-top: 1px solid ${colors.withVariableOpacity(colors.black, 0.05)};
    background-color: white;
    position: fixed;
    z-index: 9999;
    width: 100%;
    bottom: 0;
    left: 0;

    padding: 25px 0;
`

export const Text = styled.p`
    font-size: 14px;
    line-height: normal;

    ${media.tablet} {
        padding-right: 50px;
    }
`

export const Row = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${media.tablet} {
        align-items: center;
        flex-direction: row;
    }
`

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;

    padding: 24px 0 0 0;

    ${media.tablet} {
        padding: 0;
        justify-content: space-between;
    }

    button {
        padding: 12px 24px;

        :first-of-type {
            margin-right: 12px;
        }
    }
`
