import styled, { css } from "styled-components"

import { Props } from "."
import { colors, media, transition } from "@variables"
import { AccessibleButton } from "@components/shared/styled"

type P = Props & { withLink: boolean | undefined }

export const Wrap = styled(AccessibleButton)<P>`
    display: flex;
    font-size: 14px;
    font-weight: 500;
    padding: 16px 48px;
    line-height: normal;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    background-color: transparent;
    transition: ${transition.main};
    border: 2px solid ${colors.black};

    ${media.tablet} {
        :hover {
            color: white;
            background-color: ${colors.black};
        }
    }

    ${({ withLink }) =>
        withLink &&
        css`
            padding: 0;
            a {
                width: 100%;
                color: inherit;
                padding: 20px 48px;
                text-decoration: none;
                display: flex;
                align-items: center;
            }
        `}
`
