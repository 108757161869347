import styled from "styled-components"

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const Social = styled.a`
    width: 100%;
    display: flex;
    font-size: 16px;
    line-height: normal;
    align-items: center;

    :not(:last-of-type) {
        margin-bottom: 16px;
    }

    svg {
        margin-right: 8px;
    }
`
