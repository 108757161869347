import get from "lodash/get"
import { Link } from "gatsby"
import React, { useState } from "react"
import { useCookies } from "react-cookie"

import t from "@translations"
import { Routes } from "@variables"
import Button from "@components/shared/Button"
import Container from "@components/shared/Container"
import { Row, Text, Wrap, Buttons } from "./styled"

const COOKIE_NAME = "STRIKE.IT_COOKIE_BANNER"

const Cookie = () => {
    const [isSetted, setIsSetted] = useState(false)
    const [cookies, setCookie] = useCookies([COOKIE_NAME])

    if (get(cookies, COOKIE_NAME) || isSetted) {
        return null
    }

    const oneYearFromNow = new Date()
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)

    const options = { expires: oneYearFromNow }

    const accept = () => {
        setIsSetted(true)
        setCookie(COOKIE_NAME, true, options)
    }

    const reject = () => {
        setIsSetted(true)
        setCookie(COOKIE_NAME, false, options)
    }

    return (
        <Wrap>
            <Container>
                <Row>
                    <Text>
                        {t["cookie-text"]}{" "}
                        <Link to={Routes.PRIVACY}>{t["learn-more"]}</Link>
                    </Text>
                    <Buttons>
                        <Button onClick={reject}>{t["reject"]}</Button>
                        <Button onClick={accept}>{t["accept"]}</Button>
                    </Buttons>
                </Row>
            </Container>
        </Wrap>
    )
}

export default Cookie
