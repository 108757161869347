import useUIStateValue from "@hooks/useUIStateValue"
import React, { useContext, Fragment } from "react"

import t from "@translations"
import { parseMsg } from "@utils"
import { BotContext } from "src/context/Bot"
import { StrikeResponse } from "src/services/openapi"
import { AccessibleButton as Button } from "@components/shared/styled"
import {
    Wrap,
    Log,
    Head,
    Icon,
    Body,
    Msg,
    Controls,
    Control,
    MsgAuthor,
} from "./styled"

export enum MsgType {
    INCOME = "INCOME",
    OUTCOME = "OUTCOME",
}

const Bot = () => {
    const { log, start, step } = useContext(BotContext)

    const [open, setOpen] = useUIStateValue("isBotOpen")

    const toggleOpen = () => {
        setOpen(!open)
        if (log.length === 0) start()
    }

    return (
        <>
            {open && (
                <Log>
                    <Head>
                        <Icon>🤖</Icon>
                        <div>
                            <h6>{t["bot-title"]}</h6>
                            <p>{t["bot-subtitle"]}</p>
                        </div>
                    </Head>
                    <Body>
                        {log.map((msg: StrikeResponse, idx) => (
                            <Fragment key={idx}>
                                <Msg type={MsgType.OUTCOME}>
                                    <MsgAuthor type={MsgType.OUTCOME}>
                                        {t[`msg-author-user`]}
                                    </MsgAuthor>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: parseMsg(msg.title),
                                        }}
                                    />
                                </Msg>
                                <Msg type={MsgType.INCOME}>
                                    <MsgAuthor type={MsgType.INCOME}>
                                        {t[`msg-author-bot`]}
                                    </MsgAuthor>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: parseMsg(msg.content),
                                        }}
                                    />
                                </Msg>
                                {idx === log.length - 1 && (
                                    <Controls>
                                        {msg.transitions.map((t, idx) => (
                                            <Control
                                                key={idx}
                                                onClick={() => step(t.id)}
                                            >
                                                {t.title}
                                            </Control>
                                        ))}
                                    </Controls>
                                )}
                            </Fragment>
                        ))}
                    </Body>
                </Log>
            )}
            <Wrap>
                <Button role="button" onClick={toggleOpen}>
                    🤖
                </Button>
            </Wrap>
        </>
    )
}

export default Bot
