import React from "react"

import { Routes } from "@variables"
import { Wrap, Social } from "./styled"
import TelegramIcon from "@components/SVG/telegram"

const TelegramContacts = () => {
    return (
        <Wrap>
            <Social
                target="_blank"
                href={Routes.BOT_MAIN}
                rel="noreferrer noopener"
            >
                <TelegramIcon />
                <span>Основной бот Strike IT</span>
            </Social>
            <Social
                target="_blank"
                href={Routes.BOT_LAWYER_ROBOT}
                rel="noreferrer noopener"
            >
                <TelegramIcon />
                <span>Бот-юрист Strike IT</span>
            </Social>
            <Social
                target="_blank"
                href={Routes.BOT_CLAIMANT}
                rel="noreferrer noopener"
            >
                <TelegramIcon />
                <span>Бот-истец поможет составить исковое заявление</span>
            </Social>
            <Social
                target="_blank"
                href={Routes.BOT_LAWYER_DEPARTMENT}
                rel="noreferrer noopener"
            >
                <TelegramIcon />
                <span>Связаться с юридическим отделом</span>
            </Social>
            <Social
                target="_blank"
                href={Routes.BOT_ORGANIZER_DEPARTMENT}
                rel="noreferrer noopener"
            >
                <TelegramIcon />
                <span>Связаться с органайзерами</span>
            </Social>
            <Social
                target="_blank"
                href={Routes.BOT_INFO_DEPARTMENT}
                rel="noreferrer noopener"
            >
                <TelegramIcon />
                <span>Связаться с информационным отделом</span>
            </Social>
        </Wrap>
    )
}

export default TelegramContacts
