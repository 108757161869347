/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { SiteQuery } from "@graphql"

type Props = {
    title: string
    lang?: string
    description?: string
    meta?: TODO_ANY[]
}

const Seo = ({ title, description = "", lang = "ru", meta = [] }: Props) => {
    const { site } = useStaticQuery<SiteQuery>(
        graphql`
            query Site {
                site {
                    siteMetadata {
                        title
                        description
                        imagePath
                    }
                }
            }
        `
    )

    const defaultTitle = site?.siteMetadata?.title || ""
    const defaultLogoPath = site?.siteMetadata?.imagePath || ""
    const metaDescription = description || site?.siteMetadata?.description || ""

    return (
        <Helmet
            title={title}
            htmlAttributes={{ lang }}
            titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: defaultTitle
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: `${process.env.GATSBY_HOST}${defaultLogoPath}`
                }
            ].concat(meta)}
        />
    )
}

export default Seo
