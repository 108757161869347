import React, { useState } from "react"
import { Link } from "gatsby"

import t from "@translations"
import { Routes } from "@variables"
import MobileMenu from "./MobileMenu"
import Logo from "@static/images/logo.png"
import Container from "@components/shared/Container"
import { Wrap, Inner, LogoLink, Ul, Li, Burger } from "./styled"

const Nav = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleOpen = () => {
        setIsOpen(!isOpen)
        if (isOpen) document.body.classList.remove("block-scroll")
        else document.body.classList.add("block-scroll")
    }

    const close = () => {
        setIsOpen(false)
        document.body.classList.remove("block-scroll")
    }

    return (
        <>
            <Wrap>
                <Container>
                    <Inner>
                        <LogoLink to={Routes.HOME} onClick={close}>
                            <img
                                src={Logo}
                                width="160"
                                height="40"
                                loading="lazy"
                                alt="Strike.it"
                            />
                        </LogoLink>

                        <Ul>
                            <Li>
                                <h6>
                                    <Link
                                        to={Routes.HOME}
                                        activeClassName="active-link"
                                    >
                                        {t["menu-home"]}
                                    </Link>
                                </h6>
                            </Li>
                            <Li>
                                <h6>
                                    <Link
                                        to={Routes.STORIES}
                                        activeClassName="active-link"
                                    >
                                        {t["menu-stories"]}
                                    </Link>
                                </h6>
                            </Li>
                        </Ul>
                        <Burger isOpen={isOpen} onClick={toggleOpen}>
                            <span />
                            <span />
                            <span />
                        </Burger>
                    </Inner>
                </Container>
            </Wrap>
            <MobileMenu isOpen={isOpen} close={close} />
        </>
    )
}

export default Nav
