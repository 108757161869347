import React from "react"
import { Link } from "gatsby"

import t from "@translations"
import { Routes } from "@variables"
import { Wrap, Inner, Links, Hr } from "./styled"
import Container from "@components/shared/Container"
import TelegramContacts from "@components/shared/Contacts/Telegram"

type Props = {
    isOpen: boolean
    close: () => void
}

const MobileMenu = ({ isOpen, close }: Props) => {
    return (
        <Wrap isOpen={isOpen}>
            <Container>
                <Inner>
                    <Links>
                        <Link
                            onClick={close}
                            to={Routes.HOME}
                            activeClassName="active-link"
                        >
                            {t[`menu-home`]}
                        </Link>
                        <Link
                            onClick={close}
                            to={Routes.STORIES}
                            activeClassName="active-link"
                        >
                            {t[`menu-stories`]}
                        </Link>
                        {/*
                        <Link
                            onClick={close}
                            to={Routes.PRIVACY}
                            activeClassName="active-link"
                        >
                            {t[`menu-privacy`]}
                        </Link>
                        <Link
                            onClick={close}
                            to={Routes.TERMS}
                            activeClassName="active-link"
                        >
                            {t[`menu-terms`]}
                        </Link>
                        */}
                    </Links>
                    <Hr />
                    <TelegramContacts />
                </Inner>
            </Container>
        </Wrap>
    )
}
export default MobileMenu
