import styled, { css } from "styled-components"

import { colors, media, transition } from "@variables"
import { AccessibleButton } from "@components/shared/styled"

export const Wrap = styled.div`
    z-index: 10;
    border-radius: 50%;
    background-color: ${colors.red};
    border: 2px solid hsla(346, 80%, 50%, 1);

    position: fixed;
    bottom: 16px;
    height: 44px;
    width: 44px;
    right: 16px;

    ${media.tablet} {
        position: fixed;
        bottom: 40px;
        height: 88px;
        width: 88px;
        right: 40px;
    }

    button {
        width: 100%;
        height: 100%;

        font-size: 24px;

        ${media.tablet} {
            font-size: 36px;
        }
    }
`
export const Log = styled.div`
    width: 100%;
    z-index: 999999;
    position: fixed;
    overflow: hidden;
    border-radius: 4px;
    background-color: white;

    right: 16px;
    bottom: 70px;
    height: calc(100% - 86px);
    max-width: calc(100% - 32px);
    box-shadow: 0px 5px 35px rgba(7, 5, 18, 0.2);

    ${media.phone} {
        max-width: 400px;
        box-shadow: 0px 5px 35px rgba(7, 5, 18, 0.1);
    }

    ${media.tablet} {
        right: 40px;
        bottom: 160px;
        max-width: 400px;
        height: calc(100% - 200px);
    }
`

export const Head = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    padding: 22px 24px;
    align-items: center;
    background-color: #c81641;
    border-bottom: 1px solid white;

    p,
    h6 {
        color: white;
        line-height: normal;
    }
    p {
        font-size: 14px;
    }
`

export const Icon = styled.div`
    width: 38px;
    height: 38px;
    font-size: 18px;
    display: flex;
    border-radius: 50%;
    line-height: normal;
    align-items: center;
    margin-right: 14px;
    justify-content: center;
    background-color: ${colors.withVariableOpacity(colors.black, 0.15)};
`

export const Body = styled.div`
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    height: calc(100% - 80px);

    padding: 24px 10px 24px 10px;

    ${media.phone} {
        padding: 24px 10px 24px 16px;
    }
`

import { MsgType } from "."

export const Msg = styled.div<{ type: MsgType }>`
    border-radius: 4px;
    position: relative;

    font-size: 14px;
    max-width: 100%;
    min-width: 200px;
    line-height: 20px;
    padding: 16px 10px;
    margin-bottom: 12px;
    color: ${colors.black};

    ${media.phone} {
        font-size: 16px;
        line-height: 24px;
        padding: 20px 16px;
        margin-bottom: 16px;
    }

    div {
        a {
            overflow-wrap: break-word;
        }
    }

    ${({ type }) =>
        type === MsgType.OUTCOME
            ? css`
                  align-self: flex-end;
                  background-color: ${colors.withVariableOpacity(
                      colors.black,
                      0.08
                  )};
              `
            : css`
                  align-self: flex-start;
                  background-color: ${colors.whiteGray};
              `}
`

export const MsgAuthor = styled.div<{ type: MsgType }>`
    position: absolute;
    top: -9px;
    left: 8px;
    height: 18px;
    display: flex;
    padding: 0 11px;
    max-width: 130px;
    font-size: 11px;
    line-height: 13px;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);

    ${({ type }) =>
        type === MsgType.OUTCOME
            ? css`
                  background-color: ${colors.whiteGray};
              `
            : css`
                  background-color: white;
              `}
`

export const Controls = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 48px;
    justify-content: flex-end;
`

export const Control = styled(AccessibleButton)`
    text-align: left;
    color: white;
    border-radius: 4px;
    transition: ${transition.main};
    background-color: ${colors.red};

    font-size: 12px;
    line-height: 18px;
    padding: 14px 10px;
    margin: 0 12px 12px 0;

    ${media.phone} {
        font-size: 14px;
        line-height: 20px;
        padding: 20px 16px;
        margin: 0 16px 16px 0;
    }

    ${media.tablet} {
        :hover {
            background-color: ${colors.withVariableOpacity(colors.red, 0.9)};
        }
    }
`
