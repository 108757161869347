import React from "react"
import { Link } from "gatsby"

import t from "@translations"
import { Routes } from "@variables"
import Logo from "@static/images/logo.png"
import Container from "@components/shared/Container"
import TelegramContacts from "@components/shared/Contacts/Telegram"
import {
    Wrap,
    Top,
    TopTitle,
    Col,
    Bottom,
    Undertitle,
    LogoLink,
    Ul,
    Li,
} from "./styled"

const Footer = () => {
    return (
        <Wrap>
            <Container>
                <Top>
                    <Col>
                        <TopTitle>{t["footer-title1"]}</TopTitle>
                        <TopTitle>{t["footer-title2"]}</TopTitle>
                    </Col>
                    <Col>
                        <Undertitle>{t["contact-us"]}</Undertitle>
                        <TelegramContacts />
                    </Col>
                </Top>
            </Container>
            <Container>
                <Bottom>
                    <LogoLink to={Routes.HOME}>
                        <img
                            src={Logo}
                            width="160"
                            height="40"
                            loading="lazy"
                            alt="Strike.it"
                        />
                    </LogoLink>

                    <Ul>
                        <Li>
                            <h6>
                                2022
                            </h6>
                        </Li>
                    </Ul>

                    {/*
                    <Ul>
                        <Li>
                            <h6>
                                <Link
                                    to={Routes.PRIVACY}
                                    activeClassName="active-link"
                                >
                                    {t["menu-privacy"]}
                                </Link>
                            </h6>
                        </Li>
                        <Li>
                            <h6>
                                <Link
                                    to={Routes.TERMS}
                                    activeClassName="active-link"
                                >
                                    {t["menu-terms"]}
                                </Link>
                            </h6>
                        </Li>
                    </Ul>
                    */}
                </Bottom>
            </Container>
        </Wrap>
    )
}

export default Footer
