import styled from "styled-components"

export const Overflow = styled.div`
    overflow: hidden;
`

export const AccessibleButton = styled.button`
    /* Reset */
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    outline: 0; /* We disable the focus ring for mouse, touch and keyboard users. */
    font: inherit;
    overflow: visible;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    line-height: normal;
    text-decoration: none;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    background: transparent;

    ::-moz-focus-inner {
        border-style: none; /* Remove Firefox dotted outline. */
    }
    :disabled {
        pointer-events: none; /* Disable link interactions */
        cursor: default;
        opacity: 0.8;
    }
`

// Used for pages: terms, privacy
export const CommonPageWrap = styled.div`
    width: 100%;
    padding: 0 15px;
    max-width: 680px;
    margin: 112px auto;

    h1 {
        padding-bottom: 50px;
    }
    p {
        margin-bottom: 36px;
    }
`
