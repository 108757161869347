import styled from "styled-components"

import { colors } from "@variables"

export const Wrap = styled.div<{ readonly isOpen: boolean }>`
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    z-index: 9999999;
    color: ${colors.black};
    background-color: white;
    height: calc(100% - 80px);
    display: ${props => (props.isOpen ? "block" : "none")};

    a {
        color: inherit;
        text-decoration: none;
    }
`

export const Inner = styled.nav`
    width: 100%;
    display: flex;
    height: 100%;
    padding-top: 60px;
    flex-direction: column;
`

export const Links = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    a {
        font-size: 19px;
        font-weight: 500;
        line-height: normal;

        :not(:last-of-type) {
            padding-bottom: 20px;
        }
    }
`

export const Hr = styled.div`
    width: 100%;
    height: 1px;
    margin: 34px 0 40px 0;
    background-color: ${colors.withVariableOpacity(colors.black, 0.1)};
`
