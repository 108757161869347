import { useContext, useCallback } from "react"
import { UiContext, UiState } from "src/context/UI"

export type ValueOf<T> = T[keyof T]

type SetValue = (value: ValueOf<UiState>) => void

const useUIStateValue = (
    key: keyof UiState
): [ValueOf<UiState> | undefined, SetValue] => {
    const ctx = useContext(UiContext)
    const returnValue = key ? ctx[key] : undefined
    const setValue = useCallback<SetValue>(
        value => ctx.setUIStateValue(key, value),
        [ctx, returnValue, key]
    )
    return [returnValue, setValue]
}

export default useUIStateValue
