import { fontFamily } from "./../../common/variables"
import { Link } from "gatsby"
import styled from "styled-components"

import { colors, media } from "@variables"

export const LogoLink = styled(Link)`
    height: 40px; // img height
`

export const Wrap = styled.footer`
    width: 100%;
    background-color: white;
`

export const Col = styled.div`
    a {
        color: inherit;
        text-decoration: none;
    }
`

export const Top = styled.div`
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 80px 0;
    max-width: 1100px;
    flex-direction: column;

    ${Col} {
        :first-of-type {
            margin-bottom: 40px;
        }
    }

    ${media.phone} {
        padding: 160px 0;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        ${Col} {
            :first-of-type {
                margin-bottom: 0px;
            }
        }
    }
`

export const TopTitle = styled.h3`
    font-size: 36px;
    font-weight: 200;
    line-height: normal;

    :last-child {
        color: ${colors.withVariableOpacity(colors.black, 0.4)};
    }
`

export const Undertitle = styled.h6`
    padding-bottom: 30px;
`

export const Bottom = styled.div`
    width: 100%;
    display: flex;
    border-top: 1px solid ${colors.withVariableOpacity(colors.black, 0.1)};

    padding: 50px 0;
    flex-direction: column;
    align-items: center;

    ${media.desktop} {
        padding: 30px 0;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Ul = styled.ul`
    display: flex;
    list-style: none;

    padding-top: 30px;
    align-items: center;
    flex-direction: column;

    ${media.desktop} {
        padding-top: 0;
        flex-direction: row;
        align-items: flex-start;
    }
`

export const Li = styled.li`
    color: ${colors.red};

    :not(:last-of-type) {
        padding: 0 0 30px 0;
    }

    ${media.desktop} {
        :not(:last-of-type) {
            padding: 0 25px 0 0;
        }
    }
`
