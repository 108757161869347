import React, { ReactNode } from "react"
import { Link } from "gatsby"

import { Wrap } from "./styled"

export type Props = {
    children: ReactNode | string
    onClick?: () => void
    href?: string
    to?: string
}

const Button = ({ children, onClick, to, href }: Props) => {
    if (to) {
        return (
            <Wrap onClick={onClick} withLink>
                <Link to={to}>{children}</Link>
            </Wrap>
        )
    }
    if (href) {
        return (
            <Wrap onClick={onClick} withLink>
                <a href={href} target="_blank" rel="noreferrer noopener">
                    {children}
                </a>
            </Wrap>
        )
    }
    return (
        <Wrap onClick={onClick} withLink={false}>
            <span>{children}</span>
        </Wrap>
    )
}

export default Button
