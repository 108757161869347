import React from "react"

import Nav from "@components/Nav"
import Footer from "@components/Footer"
import Cookie from "@components/Cookie"
import Bot from "@components/Bot"

type Props = {
    location: Location
    children: TODO_ANY
}

const Layout = ({ location, children }: Props) => {
    return (
        <div className="global-wrapper">
            <Cookie />
            <Nav />
            <main>{children}</main>
            <Footer />
            <Bot />
        </div>
    )
}

export default Layout
