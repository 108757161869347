import React from "react"

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 21 20"
            {...props}
        >
            <path
                fill="#070512"
                d="M18.325 1.979L1.384 8.706c-.55.184-.49.979.06 1.162l4.282 1.346 1.712 5.382c.123.428.673.55.979.245l2.508-2.324 4.77 3.486a.643.643 0 00.979-.367l3.302-14.19c.184-1.04-.733-1.834-1.651-1.467zM8.417 12.254l-.55 3.18-1.224-4.404L18.57 3.202 8.417 12.254z"
            ></path>
        </svg>
    )
}

export default Icon
