export const colors = {
    red: "hsla(346, 80%, 44%, 1)",
    black: "hsla(249, 57%, 5%, 1)",
    whiteGray: "hsla(0, 0%, 98%, 1)",
    withVariableOpacity: (color: string, opacity: number) =>
        `${color.replace(`, 1)`, `, ${opacity})`)}`,
}

export const fontFamily = {
    alegreya: "Alegreya Sans",
}

enum BasicRoutes {
    HOME = "/",
    TERMS = "/terms",
    STORIES = "/news",
    PRIVACY = "/privacy",
}

enum ExternalRoutes {
    BOT_MAIN = "https://t.me/STRIKE_IT_BOT",
    BOT_LAWYER_ROBOT = "https://t.me/trudovichka_bot",
    BOT_LAWYER_DEPARTMENT = "https://t.me/Ask_urist_bot",
    BOT_ORGANIZER_DEPARTMENT = "https://t.me/strike_support_bot",
    BOT_INFO_DEPARTMENT = "https://t.me/Strikemail2_bot",
    BOT_CLAIMANT="https://t.me/claimant_bot"
}

enum HashParams {}

enum SearchParams {}

export type RoutesType =
    | BasicRoutes
    | HashParams
    | ExternalRoutes
    | SearchParams

export const Routes = {
    ...BasicRoutes,
    ...HashParams,
    ...ExternalRoutes,
    ...SearchParams,
}

export const transition = {
    main: `all linear 0.2s`,
}

const customMediaQuery = (minWidth: number): string =>
    `@media (min-width: ${minWidth}px)`

export const media = {
    custom: customMediaQuery,
    xl: customMediaQuery(1170),
    desktop: customMediaQuery(960),
    tablet: customMediaQuery(854),
    phone: customMediaQuery(480),
}
